// Import dependencies
import React from 'react';

// Import Components
import LayoutMarketing from '../../../app/components/LayoutMarketing';
import Title from '../../../app/components/Title';

// Import Styled Components
import Container from '../../../app/styles/objects/Container';

const NotFoundPage = () => (
  <LayoutMarketing>
    <Container>
      <Title typpe="h2">Page not found</Title>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </Container>
  </LayoutMarketing>
);

export default NotFoundPage;
